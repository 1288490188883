/**
 * Default: Components > Custom > Tm Slider
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Tobias Garz
 */

import {extend} from '../../../../shared/utils';
import {
	isElement,
	isString
}               from '../../../../shared/utils/is';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Data           from '../../../../shared/dom/data';
import Slider         from '../../../../shared/components/slider/slider';
import Manipulator from "../../../../shared/dom/manipulator";

// -------
// Private
// -------

const NAME      = 'tm-slider';
const DATA_KEY  = `ifab.${NAME}`;
// const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const DEFAULTS = {
	on: {
		// init : (instance) => {
		// 	showSwiperCounter(instance);
		// },
		slideChange : (instance) => {
			showSwiperCounter(instance);
		}
	}
};

/**
 * ´Tm Slider´-Counter initialisieren.
 *
 * @param {Object} instance
 * @returns {Object}
 */
const showSwiperCounter = (instance) => {
	const sliderEl = instance.el;
	const counterEl = SelectorEngine.findOne('.swiper-count-active', sliderEl);
	const activeCounter = instance.activeIndex + 1;

	counterEl.innerHTML = activeCounter;
};

/**
 * ´Tm Slider´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
const render = (element) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

	if (slider) {
		Slider.init(slider, extend({}, {
			node : slider
		}, DEFAULTS));
	}

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// -------
// Public
// -------

const init = () => {
	const collection = SelectorEngine.find(`[data-c="${NAME}"]`);

	if(!collection.length) {
		return;
	}

	for(const element of collection) {
		render(element);
	}
};

// Export
export default {
	init: init
};


