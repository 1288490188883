/**
 * Shared: Components / Form / Tools > Check group required
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {noop} from '../../../utils';

import SelectorEngine from '../../../dom/selector-engine';
import EventHandler   from '../../../dom/event-handler';
import Manipulator    from '../../../dom/manipulator';

import BaseClass from '../../../utils/base-class';

/**
 * @type {string}
 */
const NAME = 'cgr';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	scrollToInvalidField: true,
	onInit              : noop, // (event) => { console.log('onInit', event); },
	onValidationFailed  : noop, // (event) => { console.log('onValidationFailed', event); },
	onValidationSuccess : noop //(event) => { console.log('onValidationSuccess', event); }
};

/**
 *  Class
 */
class CheckGroupRequired extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = CheckGroupRequired.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this._setup();
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		const elements = SelectorEngine.find('[type="checkbox"], [type="radio"]', this._element);

		EventHandler.on(this._element, `handleRequired${this.constructor.EVENT_KEY}`, () => {
			const checked = SelectorEngine.findOne(':checked', this._element);

			for(const element of elements) {
				Manipulator.setRequired(element, (!checked));
			}
		});

		EventHandler.on(this._element, `click${this.constructor.EVENT_KEY}`, '[type="checkbox"], [type="radio"]', () => {
			EventHandler.trigger(this._element, `handleRequired${this.constructor.EVENT_KEY}`);
		});

		EventHandler.trigger(this._element, `handleRequired${this.constructor.EVENT_KEY}`);
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default CheckGroupRequired;
