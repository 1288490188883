/**
 * Shared: Components > Reveal
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	extend,
	getTransitionDuration,
	executeAfterTransition
}  from '../../utils';
import {isNumber} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';
import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME      = 'reveal';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const DEFAULTS = {
	container            : null,
	duration             : 150,
	transitionFromElement: true,
	easing               : 'ease-in-out',
	visibleDisplay       : 'block'
};

/**
 *
 * @param{HTMLElement}  element
 * @param {Object|number} [options={}]
 */
const buildOptions = (element, options = {}) => {
	const _o = extend({}, DEFAULTS, (isNumber(options) ? {duration: options} : options));

	if (_o.transitionFromElement) {
		const elementDuration            = getTransitionDuration(element);
		const {transitionTimingFunction} = window.getComputedStyle(element);

		_o.duration = elementDuration;
		_o.easing   =  transitionTimingFunction.split(',')[0] || DEFAULTS.easing;
	}

	return _o;
};

/**
 *
 * @param{HTMLElement}  element
 */
const getLayoutStyles = (element) => {
	const gcs        = getComputedStyle(element);
	const properties = [
		'display',
		'border-top-width',
		'border-bottom-width',
		'padding-bottom',
		'padding-top',
		'margin-bottom',
		'margin-top'
	];

	let res = {};

	for (const property of properties) {
		res[property] = gcs.getPropertyValue(property);
	}

	return res;
};

/**
 *
 * @param{HTMLElement}  element
 */
const resetLayoutStyles = (element) => {
	element.style.overflow          = 'hidden';
	element.style.height            = '0';
	element.style.borderBottomWidth = '0';
	element.style.borderTopWidth    = '0';
	element.style.paddingTop        = '0';
	element.style.paddingBottom     = '0';
	element.style.marginTop         = '0';
	element.style.marginBottom      = '0';
};

/**
 *
 * @param{HTMLElement}  element
 */
const cleanUpStyleAttr = (element) => {
	element.style.removeProperty('height');
	element.style.removeProperty('overflow');
	element.style.removeProperty('border-top-width');
	element.style.removeProperty('border-bottom-width');
	element.style.removeProperty('padding-bottom');
	element.style.removeProperty('padding-top');
	element.style.removeProperty('margin-bottom');
	element.style.removeProperty('margin-top');
	element.style.removeProperty('transition-duration');
	element.style.removeProperty('transition-property');

	// Leeres Style-Attribut entfernen?
	if (element.getAttribute('style') === '') {
		element.removeAttribute('style');
	}
};

// ------
// Public
// ------

/**
 *
 * @param{HTMLElement}  element
 * @param {Object|number} [options={}]
 */
const hide = (element, options = {}) => {
	const gels      = getLayoutStyles(element);
	const isHidden = gels.display === 'none' || element.hidden;

	// Ist Element schon ausgeblendet, dann muss man nichts machen.
	if (isHidden) {
		return Promise.resolve(element);
	}

	// Aktuelle Optionen.
	const _o = buildOptions(element, options);

	//
	// Ausblenden des Elementes.
	//

	element.style.height             = `${element.offsetHeight}px`;
	element.style.transitionProperty = 'all';
	element.style.transitionDuration = `${_o.duration}ms`;

	// Trigger reflow.
	element.offsetHeight;

	Manipulator.setDataAttribute(element, 'reveal', 'hide');

	// Layoutstyles des Elementes zurücksetzen, da bis ´0´ das Ausblenden
	// animiert wird.
	resetLayoutStyles(element);

	return new Promise(function(resolve) {
		executeAfterTransition(
			() => {
				cleanUpStyleAttr(element);

				Manipulator.setAria(element, 'hidden', 'true');

				element.style.display = 'none';

				element.hidden = true;

				resolve(element);
			},
			element
		);
	});
};

/**
 *
 * @param{HTMLElement}  element
 * @param {Object|number} [options={}]
 */
const show = (element, options = {}) => {
	const gels     = getLayoutStyles(element);
	const isHidden = gels.display === 'none' || element.hidden;

	// Ist Element schon eingeblendet, dann muss man nichts machen.
	if (!isHidden) {
		return Promise.resolve(element);
	}

	// Aktuelle Optionen.
	const _o = buildOptions(element, options);

	// Sichtbarkeit des Elementes setzen.
	element.hidden        = false;
	element.style.display = _o.visibleDisplay;

	// Höhe des Elementes.
	const height  = element.offsetHeight;

	Manipulator.setDataAttribute(element, 'reveal', 'show');

	// Layoutstyles des Elementes zurücksetzen, da von ´0´ an das Einblenden
	// animiert wird.
	resetLayoutStyles(element);

	// Trigger reflow.
	element.offsetHeight;

	//
	// Einblenden des Elementes.
	//

	// Animation
	element.style.transitionProperty = 'all';
	element.style.transitionDuration = `${_o.duration}ms`;

	// Elementhöhe setzen
	element.style.height = `${height}px`;

	// Vorherige zurückgesetzte Elementlayoutwerte wieder setzen.
	element.style.borderBottomWidth = gels['border-bottom-width'];
	element.style.borderTopWidth    = gels['border-top-width'];
	element.style.paddingBottom     = gels['padding-bottom'];
	element.style.paddingTop        = gels['padding-top'];
	element.style.marginBottom      = gels['margin-bottom'];
	element.style.marginTop         = gels['margin-top'];

	return new Promise(function(resolve) {
		executeAfterTransition(
			() => {
				cleanUpStyleAttr(element);

				Manipulator.setAria(element, 'hidden', 'false');

				resolve(element);
			},
			element
		);
	});
};

/**
 *
 * @param{HTMLElement}  element
 * @param {Object|number} [options={}]
 */
const toggle = (element, options = {}) => {
	// Aktuelle Optionen.
	const _o = buildOptions(element, options);

	return window.getComputedStyle(element).display === 'none' ? show(element, _o) : hide(element, _o);
};

/**
 * Alle vorhandenen ´Reveal password´ initialisieren.
 *
 * @param {Object} [o={}]
 */
const init = (o = {}) => {
	const _o       = extend({}, DEFAULTS, o);
	const controls = SelectorEngine.find(
		`[data-${NAME}-show], [data-${NAME}-hide], [data-${NAME}-toggle]`,
		_o.container || document.documentElement
	);

	for (const element of controls) {
		if (!Data.get(element, `${DATA_KEY}.initialized`)) {
			const attrShow   = Manipulator.getDataAttribute(element, `${NAME}-show`);
			const attrHide   = Manipulator.getDataAttribute(element, `${NAME}-hide`);
			const attrToggle = Manipulator.getDataAttribute(element, `${NAME}-toggle`);

			let targets = [];

			if (attrShow) {
				targets = SelectorEngine.find(attrShow);
			} else if (attrHide) {
				targets = SelectorEngine.find(attrHide);
			} else if (attrToggle) {
				targets = SelectorEngine.find(attrToggle);
			}

			EventHandler.on(element, `click${EVENT_KEY}`, (event) => {
				event.preventDefault();

				if (targets.length) {
					for (const target of targets) {
						if (attrShow) {
							show(target);
						} else if (attrHide) {
							hide(target);
						} else if (attrToggle) {
							toggle(target);
						}
					}
				}
			});

			// Initialisierungsstatus setzen.
			Data.set(element, `${DATA_KEY}.initialized`, true);
		}
	}
};

// Export
export default {
	init,
	show,
	hide,
	toggle
};
