/**
 * Shared: Components > Jumplist
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	isElement,
	isString,
	isVisible
} from '../../utils/is';
import {
	execute,
	extend,
	getElementFromSelector,
	noop
} from '../../utils';
import {scrollElementIntoView} from '../../utils/scroll';

import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';
import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME       = 'jumplist';
const DATA_KEY   = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
const API_KEY    = `.data-api`;

function Jumplist(element, o = {}) {
	if (!isElement(element)) {
		return null;
	}

	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return Data.get(element, API_KEY);
	}

	this.options = extend({}, Jumplist.DEFAULTS, o);

	this._element     = element;
	this._container   = getElementFromSelector(this._element);
	this._links       = new Map();
	this._sections    = new Map();
	this._observer    = null;

	// Rendering (Container, Events, ...)
	this._render();

	// API-Zugriffsmöglichkeiten
	const api = {
		activate    : this.activate.bind(this),
		refresh     : this.refresh.bind(this),
		getContainer: () => {
			return this._container;
		}
	};

	// API im Container verfügbar machen
	Data.set(this._element, API_KEY, api);

	// Initialisierungsstatus setzen.
	Data.set(this._element,`${DATA_KEY}.initialized`, true);

	return api;
}

/**
 * Standardoptionen.
 *
 * @constant {Object}
 */
Jumplist.DEFAULTS = {
	label          : 'Jump list menu',
	rootMargin     : '0px',
	selectorTargets: `[data-${NAME}-name]`,
	windowMode     : false,
	onInit         : noop,
	onNavigate     : noop,
	onActivate     : noop,
	onDeactivate   : noop,
	onRefresh      : noop
};

Jumplist.prototype.activate = function(hash) {
	const link = this._links.get(hash);

	if (link) {
		this._process(link);
	}
};

Jumplist.prototype.refresh = function() {
	Manipulator.addClass(this._element, '-refresh');

	this._links.clear();
	this._sections.clear();

	// Observe
	if (this._observer) {
		this._observer.disconnect();
	}

	this.menu.textContent = '';

	this._refresh(true);
};

Jumplist.prototype._render = function() {
	Manipulator.setRole(this._element, 'navigation');
	Manipulator.setAria(
		this._element,
		'label',
		Manipulator.getAria(this._element, 'label') || this.options.label
	);

	const jlContainer = Manipulator.createElementFrom(`<div class="${NAME}__container"/>`);
	Manipulator.elementAppend(jlContainer, this._element);
	Manipulator.addClass(this._element, '-refresh');

	this.menu = Manipulator.createElementFrom(`<div class="${NAME}-menu"/>`);
	Manipulator.elementAppend(this.menu, jlContainer);

	this._refresh(false);
};

Jumplist.prototype._refresh = function(isApiCall) {
	this._observer = this._newObserver();

	// Einträge generieren
	this._initTargetsAndObservables();

	// Events
	this._bindEvents();

	for (const section of this._sections.values()) {
		this._observer.observe(section);
	}

	Manipulator.removeClass(this._element, '-refresh');

	//
	// Callbacks
	//

	const links    = Array.from(this._links.values());
	const sections = Array.from(this._sections.values());

	if(isApiCall) {
		// Aufruf erfolgte über die API.
		const eventRefresh = EventHandler.trigger(this._element, `refresh${EVENT_KEY}`, {
			links,
			sections
		});

		execute(
			this.options.onRefresh,
			eventRefresh
		);
	} else {
		const eventInit = EventHandler.trigger(this._element, `init${EVENT_KEY}`, {
			links,
			sections
		});

		execute(
			this.options.onInit,
			eventInit
		);
	}
};

Jumplist.prototype._initTargetsAndObservables = function() {
	const targets = SelectorEngine.children(this._container, this.options.selectorTargets);

	for (const target of targets) {
		const id       = target.getAttribute('id');
		const attrName = Manipulator.getDataAttribute(target, `${NAME}-name`);

		let text;

		if (attrName.startsWith('#') || attrName.startsWith('.')) {
			const elm = SelectorEngine.findOne(attrName);

			if (elm) {
				text = elm.innerHTML;
			}
		}

		if (!text) {
			text = attrName;
		}

		if (id && text && isVisible(target)) {
			const hash   = `#${id}`;
			const anchor = Manipulator.createElementFrom(`<a class="${NAME}-menu__link" href="${location.href.split('#')[0]}${hash}"><span class="${NAME}-menu__link-text">${text}</span></a>`);

			Manipulator.elementAppend(anchor, this.menu);

			anchor.jumplistHash = hash;
			anchor.jumplistText = text;
			target.jumplistHash = hash;
			target.jumplistText = text;

			this._sections.set(hash, target);
			this._links.set(hash, anchor);
		}
	}
};

Jumplist.prototype._bindEvents = function() {
	EventHandler.off(this._element, `click${EVENT_KEY}`);

	EventHandler.on(this._element, `click${EVENT_KEY}`, `.${NAME}-menu__link`, (event) => {
		event.preventDefault();
		// event.stopPropagation();

		this._process(event.delegateTarget);
	});
};

Jumplist.prototype._process = function(link) {
	const section = this._sections.get(link.jumplistHash);

	if (section) {
		const eventNavigate = EventHandler.trigger(this._element, `navigate${EVENT_KEY}`, {
			link,
			section
		});

		execute(
			this.options.onNavigate,
			eventNavigate
		);

		if (this.options.windowMode) {
			section.scrollIntoView({
				left    : 0,
				block   : 'start',
				behavior: 'smooth'
			});
		} else {
			scrollElementIntoView(section, this._container, 'both', 'smooth');
		}
	}
};

Jumplist.prototype._newObserver = function() {
	return new IntersectionObserver(entries => this._callbackObserver(entries), {
		root      : (this.options.windowMode) ? null : this._container,
		threshold : [0.1, 0.5, 1],
		rootMargin: this.options.rootMargin
	});
};

Jumplist.prototype._callbackObserver = function(entries) {
	for (const element of entries) {
		const section = element.target;
		const link    = this._links.get(section.jumplistHash);

		if (element.isIntersecting) {
			if (!link.classList.contains('_active')) {
				Manipulator.addClass(link, '_active');

				const eventActivate = EventHandler.trigger(this._element, `activate${EVENT_KEY}`, {
					link,
					section
				});

				execute(
					this.options.onActivate,
					eventActivate
				);
			}
		} else {
			if (link.classList.contains('_active')) {
				Manipulator.removeClass(link, '_active');

				const eventDeactivate = EventHandler.trigger(this._element, `deactivate${EVENT_KEY}`, {
					link,
					section
				});

				execute(
					this.options.onDeactivate,
					eventDeactivate
				);
			}
		}
	}
};

// -------
// Public
// -------

/**
 * ´Jumplist´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	let group;

	if (isElement(m)) {
		group = new Jumplist(m, o);
	} else {
		const collection = SelectorEngine.find((isString(m)) ? m : `[data-c="${NAME}"]`, document.documentElement);

		group = [];

		for (const element of collection) {
			const inst = new Jumplist(element, o);

			group.push(inst);
		}
	}

	return group;
};

// Export
export default {
	init: init
};
