/**
 * Shared: Components > Form
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../utils';

import SelectorEngine from '../../dom/selector-engine';

import CheckGroupRequired   from './tools/check-group-required';
import ClearableField       from './tools/clearable-field';
import ToggleReadOnly       from './tools/toggle-readonly';
import ToggleFormSection    from './tools/toggle-form-section';
import NativeFormValidation from './tools/native-form-validation';

// -------
// Private
// -------

const NAME      = 'form';
const DATA_KEY  = `ifab.${NAME}`;
// const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const DEFAULTS = {
	checkGroupRequired  : {},
	clearableField      : {},
	toggleReadOnly      : {},
	toggleFormSection   : {},
	nativeFormValidation: {}
};

// ------
// Public
// ------

/**
 * Initialisierungen ´Check group required´.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {CheckGroupRequired}
 */
const checkGroupRequired = (element, o = {}) => {
	const _o = extend({}, DEFAULTS.checkGroupRequired, o);

	return new CheckGroupRequired(element, _o);
};

/**
 * Initialisierungen ´Clearable field´.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {ClearableField}
 */
const clearableField = (element, o = {}) => {
	const _o = extend({}, DEFAULTS.clearableField, o);

	return new ClearableField(element, _o);
};

/**
 * Initialisierungen ´Toggle read-only field´.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {ToggleReadOnly}
 */
const toggleReadOnly = (element, o = {}) => {
	const _o = extend({}, DEFAULTS.toggleReadOnly, o);

	return new ToggleReadOnly(element, _o);
};

/**
 * Initialisierungen ´Toggle form section´.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {ToggleReadOnly}
 */
const toggleFormSection = (element, o = {}) => {
	const _o = extend({}, DEFAULTS.toggleFormSection, o);

	return new ToggleFormSection(element, _o);
};

/**
 * Initialisierungen ´Native form validation´.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {NativeFormValidation}
 */
const nativeFormValidation = (element, o = {}) => {
	const _o = extend({}, DEFAULTS.nativeFormValidation, o);

	return new NativeFormValidation(element, _o);
};

/**
 * Initialisierung.
 * Standardinitialisierungen von Form tools etc..
 *
 * @param {Object} [o={}]
 * @returns {Object}
 */
const init = (o = {}) => {
	const _o       = extend({}, DEFAULTS, o);
	const affected = {};

	//
	// Checkbox-, Radiogruppen (multiple & required) initialisieren
	//

	const reqCheckGroups = SelectorEngine.find('[data-check-group-required]');

	if (reqCheckGroups.length) {
		affected['requiredCheckGroups'] = [];

		for (const group of reqCheckGroups) {
			affected['requiredCheckGroups'].push(checkGroupRequired(group, _o.checkGroupRequired));
		}
	}

	//
	// Formulare initialisieren
	//

	const forms = SelectorEngine.find('form[novalidate]');

	if (forms.length) {
		affected['formNoValidate'] = [];

		for (const element of forms) {
			affected['formNoValidate'].push(new NativeFormValidation(element, _o.nativeFormValidation));
		}
	}

	//
	// Clearable fields
	//

	const clearableFields = SelectorEngine.find('input[data-clearable]:not([data-provider="autocomplete"])');

	if (clearableFields.length) {
		affected['clearableField'] = [];

		for (const element of clearableFields) {
			affected['clearableField'].push(clearableField(element, _o.clearableField));
		}
	}

	//
	// Toggle read-only fields
	//

	const toggleReadOnlyFields = SelectorEngine.find('[data-toggle-readonly]');

	if (toggleReadOnlyFields.length) {
		affected['toggleReadOnly'] = [];

		for (const element of toggleReadOnlyFields) {
			affected['toggleReadOnly'].push(toggleReadOnly(element, _o.toggleReadOnly));
		}
	}

	//
	// Toggle form sections
	//

	const groupToggleFormSection = SelectorEngine.find('[data-toggle-form-section]');

	if (groupToggleFormSection.length) {
		affected['toggleFormSection'] = [];

		for (const element of groupToggleFormSection) {
			affected['toggleFormSection'].push(toggleFormSection(element, _o.toggleFormSection));
		}
	}

	return affected;
};

// Export
export default {
	init,
	checkGroupRequired,
	toggleReadOnly,
	toggleFormSection,
	clearableField,
	nativeFormValidation
};
