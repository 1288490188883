/**
 * Sources / Default / Components / Custom / Numbered Slider
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Tobias Garz
 */

import {extend} from '../../../../shared/utils';
import {
	isElement,
	isString
} from '../../../../shared/utils/is';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Data           from '../../../../shared/dom/data';
import Slider from "../../../../shared/components/slider/slider";

// -------
// Private
// -------

const NAME     = 'numbered-slider';
const DATA_KEY = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const DEFAULTS = {
	calculateHeight: true,
	autoHeight: true,
	cssMode: true,
	observer: true,
	observeParents: true,
	lazy: false,
	// on: {
	// 	afterInit: (swiper) => {
	// 		console.log('after init');
	//
	// 		calculateSliderHeight(swiper);
	// 	},
	// 	slideChange: (swiper) => {
	// 		console.log('slideChange');
	//
	// 		setTimeout(function() {
	// 			calculateSliderHeight(swiper);
	// 		}, 250);
	// 	}
	// }
};

const calculateSliderHeight = (swiper) => {
	const wrapper = SelectorEngine.findOne('.swiper-wrapper', swiper.el);

	$(wrapper).css({
		height: swiper.slides[swiper.activeIndex].offsetHeight
	});
};

const renderSlider = (element, o) => {
	Slider.init(element, o);
};

/**
 * ´Media gallery´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	//
	// Slider
	//

	const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

	if (slider) {
		renderSlider(slider, extend({}, {
			slidesPerView: 'auto',
			spaceBetween : 0
		}, o));
	}

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// -------
// Public
// -------

/**
 * ´Media gallery´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(render(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init: init
};

