/**
 * Shared: Components / Form / Tools > Toggle form section
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	getElementFromSelector,
	noop
} from '../../../utils';

import SelectorEngine from '../../../dom/selector-engine';
import EventHandler from '../../../dom/event-handler';
import Manipulator from '../../../dom/manipulator';

import BaseClass from '../../../utils/base-class';

import Reveal from '../../reveal/reveal';

/**
 * @type {string}
 */
const NAME = 'toggle-form-section';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	onInit : noop, // (event) => { console.log('onInit', event); },
	onSet  : noop, // (event) => { console.log('onSet', event); },
	onUnset: noop // (event) => { console.log('onUnset', event); }
};

/**
 *  Class
 */
class ToggleFormSection extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = ToggleFormSection.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		// Eingabefeld vom Attribut ´data-target´ des Toggle (`this._element`) holen.
		this.target = getElementFromSelector(this._element);

		if (this.target) {
			this._setup();
		}
	}

	show() {
		Reveal
			.show(this.target)
			.then(() => {
				if (this.requiredFields.length > 0) {
					for (const field of this.requiredFields) {
						field.setAttribute('required', 'true');
					}
				}
			});
	}

	hide() {
		Reveal
			.hide(this.target)
			.then(() => {
				if (this.requiredFields.length > 0) {
					for (const field of this.requiredFields) {
						field.removeAttribute('required');

						Manipulator.removeClass(field, '-validation-failed');
					}
				}
			});
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_setup() {
		//
		// Festlegung des Toggletyps bzgl. Anbindung von Events.
		// Ein Toggle kann eine Checkbox oder auch eine Option eines Select sein.
		//

		this.toggle     = null;
		this.toggleType = this._element.getAttribute('type');

		const tagName = this._element.tagName.toLowerCase();

		if (
			tagName === 'input' &&
			['checkbox', 'radio'].indexOf(this.toggleType) >= 0
		) {
			// Checkbox ...
			this.toggle      = this._element;
			this.toggleType = 'input';
			this.toggleEvent = 'click';
		} else if (tagName === 'option') {
			// Select ...
			const select = SelectorEngine.parents(this._element, 'select');

			if (select[0]) {
				this.toggle      = select[0];
				this.toggleType  = 'select';
				this.toggleEvent = 'change';
			}
		}

		// Weitere Initialisierung nur, wenn auch `this.toggle` vorhanden ist.
		if (this.toggle) {
			// Pflichtfelder, wenn vorhanden in `this.target`, zusammenstellen.
			this.requiredFields = SelectorEngine.find('input[required], select[required]', this.target);

			this._addListeners();
		}
	}

	/**
	 * Events anbinden.
	 * @private
	 */
	_addListeners() {
		this.eventParams = {
			target: this.target,
			toggle: this.toggle
		};

		EventHandler.on(this.toggle, `handle${this.constructor.EVENT_KEY}`, this._handler.bind(this));

		EventHandler.on(this.toggle, `${this.toggleEvent}${this.constructor.EVENT_KEY}`, (event) => {
			EventHandler.trigger(this.toggle, `handle${this.constructor.EVENT_KEY}`);
		});

		EventHandler.trigger(this.toggle, `handle${this.constructor.EVENT_KEY}`);

		const eventInit = EventHandler.trigger(this._element, `init`, this.eventParams);

		execute(this._config.onInit, eventInit);
	}

	/**
	 * Handler-Funktion für das Setzen von Read-only.
	 * @private
	 */
	_handler() {
		let flag = false;

		if (this.toggleType === 'input') {
			flag = this.toggle.checked;
		}

		if (this.toggleType === 'select') {
			let options = this.toggle.selectedOptions;

			if (options.length > 0) {
				let optionFound = false;

				for (const option of options) {
					if (!optionFound && option.dataset.toggleFormSection !== undefined) {
						optionFound = true;

						flag = true;
					}
				}
			}
		}


		if (flag) {
			this.show();
		} else {
			this.hide();
		}
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default ToggleFormSection;
