/**
 * Shared: Components > Progress bar
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	extend,
	noop
} from '../../utils';
import {
	isElement,
	isString
} from '../../utils/is';

import Manipulator    from '../../dom/manipulator';
import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';
import SelectorEngine from '../../dom/selector-engine';

// -------
// Private
// -------

const NAME      = 'progress-bar';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const DEFAULTS = {
	container  : null,
	checkInView: true,
	onInit     : noop,
	onUpdated  : noop
};

const observerInView = new IntersectionObserver((entries) => {
	for (const element of entries) {
		if (element.isIntersecting) {
			handleInit(element.target);
		}
	}
}, {
	root     : null,
	threshold: [0.75]
});

/**
 * Monitor progress bar changes.
 *
 * @type {MutationObserver}
 */
const observerMutation = new MutationObserver((mutations) => {
	for (const mutation of mutations) {
		if (
			mutation.type === 'attributes' &&
			(
				mutation.attributeName === 'data-value' ||
				mutation.attributeName === 'data-indeterminate'
			)
		) {
			handleUpdate(mutation.target, true);
		}
	}
});

/**
 * Ausführung bei Elementaktualisierung.
 *
 * @param {HTMLElement|Node} element
 * @param {Boolean} triggerEvent
 */
const handleUpdate = (element, triggerEvent) => {
	const value     = parseInt(Manipulator.getDataAttribute(element, 'value') || 0, 10);
	const options   = Data.get(element, 'options');
	const label     = Data.get(element, 'label');
	const indicator = Data.get(element, 'indicator');

	Manipulator.setAria(element, 'valuenow', value);

	element.value = value;

	indicator.style.width = `${value}%`;

	if (label) {
		label.textContent = `${value}%`;
	}

	// Trigger updated event
	if (triggerEvent) {
		const eventUpdated = EventHandler.trigger(element, `updated${EVENT_KEY}`);

		execute(
			options.onUpdated,
			eventUpdated
		);
	}
};

/**
 * Ausführung bei Elementinitialisierung.
 *
 * @param {HTMLElement|Node} element
 */
const handleInit = (element) => {
	const options   = Data.get(element, 'options');
	const eventInit = EventHandler.trigger(element, `init${EVENT_KEY}`);

	observerInView.unobserve(element);

	handleUpdate(element, false);

	execute(
		options.onInit,
		eventInit
	);
};

/**
 * ´Progress bar´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const ariaLabel = Manipulator.getDataAttribute(element, 'label') || 'Progress bar';
	const value     = parseInt(Manipulator.getDataAttribute(element, 'value') || 0, 10);
	const label     = element.textContent || '';

	element.textContent = '';
	element.value       = value;

	//
	// Accessibility
	//

	Manipulator.removeDataAttribute(element, 'label');

	Manipulator.setAria(element, 'label', ariaLabel);
	Manipulator.setAria(element, 'valuemin', 0);
	Manipulator.setAria(element, 'valuemax', 100);
	Manipulator.setAria(element, 'valuenow', value);
	Manipulator.setRole(element, 'progressbar');

	//
	// Scaffolding
	//

	const indicator = Manipulator.elementAppend(`<div class="${NAME}__indicator" style="width:0;"></div>`, element);

	if (label) {
		const lbl = Manipulator.elementAppend(`<span class="${NAME}__label">${label}%</span>`, indicator);

		Data.set(element, `label`, lbl);
	}

	Data.set(element, 'indicator', indicator);
	Data.set(element, 'options', o);

	//
	// Observer
	//

	if (o.checkInView) {
		observerInView.observe(element);
	} else {
		handleInit(element);
	}

	observerMutation.observe(element, {
		attributes: true
	});

	//
	// Initialisierungsstatus setzen.
	//

	Data.set(element,`${DATA_KEY}.initialized`, true);
};

// -------
// Public
// -------

/**
 * ´Tag´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(render(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init: init
};
