/**
 * Navigation
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import EventHandler from '../../../shared/dom/event-handler';

import Nav from '../../../shared/components/nav/nav';
import Drawer from '../../../shared/components/drawer/drawer';

// -------
// Private
// -------

const NAME      = 'menu';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const pageMenu = SelectorEngine.findOne('#page-menu-drawer');

let mainNavInstance;

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const trigger = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');
	const drawer  = new Drawer(pageMenu, {
		placement: 'end',
		// onInit   : (event) => {
		// 	const componentCloseButton = SelectorEngine.findOne('.drawer__header > .drawer-close', event.target);
		//
		// 	if (componentCloseButton) {
		// 		componentCloseButton.remove();
		// 	}
		// },
		onShow   : function() {
			Manipulator.addClass(trigger, '-active');
		},
		onHidden : () => {
			Manipulator.removeClass(trigger, '-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.delegateTarget.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});
};

const setupSubMenuDrawer = (link) => {
	const navId = Manipulator.getAria(link, 'controls');
	const page = SelectorEngine.findOne('#page');
	const pageSubMenu = SelectorEngine.findOne(`#page-submenu-drawer-${navId}`);
	const drawer = new Drawer(pageSubMenu, {
		placement: 'end',
		// onInit   : (event) => {
		// 	const componentCloseButton = SelectorEngine.findOne('.drawer__header > .drawer-close', event.target);
		//
		// 	if (componentCloseButton) {
		// 		componentCloseButton.remove();
		// 	}
		// },
		onShow   : function() {
			Manipulator.addClass(page, '-submenu-shown');
			Manipulator.addClass(link, '-active');

			const subMenus = SelectorEngine.find(`.page-submenu:not(#page-submenu-drawer-${navId})`);

			if(subMenus.length) {
				for(const subMenu of subMenus) {
					const subDrawer = Drawer(subMenu);

					subDrawer.hide();
				}
			}
		},
		onHidden : () => {
			Manipulator.removeClass(page, '-submenu-shown');
			Manipulator.removeClass(link, '-active');
		}
	});

	EventHandler.on(link, 'click.ifab.pageMenuDrawer', (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.delegateTarget.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});
};

/**
 * Initialisierung der eigentlichen Navigationsliste.
 */
const navigation = () => {
	const collection = SelectorEngine.find('.main-nav > .nav-list > .nav-item > .nav-link.-has-sub');

	if (collection.length) {
		for(const link of collection) {
			setupSubMenuDrawer(link);
		}
	}
};

// -------
// Public
// -------

const menu = () => {
	if (pageMenu) {
		setupDrawer();
		navigation();
	}
};

// Export
export default menu;
